@import '~@app/scss/variables.scss';

$section-margin: 5px;

.documentation {
	padding: 40px 100px;

	a:hover {
		color: $brand-dark-orange;
	}

	&__title {
		margin-bottom: 2 * $section-margin;
		text-align: center;
	}

	&__subtitle {
		margin: 3 * $section-margin 0 $section-margin 0;
		font-size: 18px;
		line-height: 22px;
	}

	p {
		line-height: 1.4;
	}

	&__text-block {
		display: inline;
		line-height: 1.4;
	}

	&__section ~ &__section {
		margin-top: 8 * $section-margin;
	}

	.text-uppercase {
		text-transform: uppercase;
	}

	a {
		color: $brand-blue;
		text-decoration: none;
	}

	p ~ p {
		margin-top: 2 * $section-margin;
	}

	@media screen and (max-width: $width-tablet) {
		padding: 19px 17px;
	}
}

// PRIVACY POLICY PAGE
.privacy-policy {
	.contact-list {
		margin: 0;
		list-style: none;
		padding: 0;
	}
}

// GENERAL TERMS PAGE
.general-terms {
	&__pdf-link {
		display: flex;
		align-items: center;
		margin: 10px 0;
		width: fit-content;
	}

	&__pdf-icon {
		width: 20px;
		margin-right: 5px;
	}
}

// WHY OVC PAGE
.choose-ovc {
	&__partners-block {
		display: flex;
		flex-direction: column;
		gap: .5em;

		&__images {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			flex-wrap: wrap;
			gap: .5em;

			@media (max-width: $width-tablet) {
				& {
					justify-content: center;
				}
			}
		}
	}

	.documentation__subtitle {
		display: flex;
		flex-direction: row;
		gap: $section-margin;
		align-items: center;
	}

	&__partners-img {
		width: 80px;
		margin-right: $section-margin;
	}
}

// INVESTORS
.investor-relations {
	ul {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			line-height: 1.4;
		}
	}

	.rc-tabs-ink-bar {
		background: $brand-blue;
		height: 2px;
	}

	.rc-tabs-nav {
		align-items: baseline;
	}

	.rc-tabs-nav-wrap {
		margin-bottom: 20px;
	}

	.rc-tabs-tab {
    margin-right: 20px;
		font-size: 14px;
    line-height: 16px;
	}
}


// Documentation Requirements
.doc-requirements {
	&__intro-block {
		display: flex;

		img {
			width: 200px;
			height: 224px;
			margin-left: 20px;
		}
	}

	&__list {
		line-height: 1.4;

		margin: 0;
		padding: 0 0 0 36px;
	}

	&__list-without-padding {
		line-height: 1.4;

		margin: 0;
		padding: 0 0 0 18px;
	}

	@media screen and (max-width: $width-tablet) {
		&__intro-block {
			flex-direction: column-reverse;
			align-items: center;
		}
	}
}

//  TRAVEL INSURANCE
.travel-insurance {
	&__list {
		line-height: 1.4;

		margin: 0;
		padding: 0 0 0 18px;
	}

	&__table {
		.rc-table-thead {
			.rc-table-cell {
				background: #104E8B !important;
				color: #FFF;
				font-weight: bold;
				text-align: left;
			}
		}
	}
}

//  ABOUT US
.about-us {
	&__color {
		color: $brand-blue;
	}

	&__intro {
		display: flex;
		flex-direction: row;
		gap: 6 * $section-margin;

		&__image {
			display: flex;
			align-items: center;

			@media screen and (max-width: $width-tablet--lg) {
				align-items: flex-start;
			}

			& > img {
				max-width: 120px;
				max-height: 120px;
			}
		}
	}

	&__price-img {
		max-width: 150px;
		max-height: 150px;
		padding: 2 * $section-margin;
		padding-right: 0;
	}

	&__leadership-row {
		margin-bottom: $section-margin;
		border: 2px solid $brand-blue;
	}

	&__leadership-ship {
		padding: 20px;
		background-color: $brand-blue;
		color: $white;
		text-align: center;
	}

	&__leadership-name {
		border-bottom: 1px solid $white;
		padding-bottom: 15px;
	}

	&__leadership-grade {
		padding-top: 10px;
	}

	&__brands-card {
		padding: 20px;
		box-shadow: -1px 4px 17px -7px rgb(21 38 47 / 24%);
		border: 1px solid #efefef;
		background: $white;
		text-align: center;
		height: 100%;
		overflow: hidden;

		img {
			object-fit: contain;
			width: 90%;
			max-width: 250px;
			max-height: 32px;
		}
	}

	&__brands-text {
		border-top: 1px solid #efefef;
		border-bottom: 1px solid #efefef;
		padding: 10px 0;
	}

	&__brands__footer {
		margin-bottom: -3 * $section-margin;
	}

	.partners__brands {
		display: flex;
		justify-content: center;

		&__content {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			gap: 20px;
			max-width: 60%;

			& > a {
				transition: transform 0.25s ease;

				&:hover {
					transform: scale(1.1);
				}
			}

			& img {
				max-width: 200px;
				max-height: 60px;
			}
		}
	}

	.accolades-links {
		display: flex;
		gap: 4 * $section-margin;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-end;
	}

	.accolades-link {
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
	}

	&__pledge-cards-container {
		list-style-type: none;
		margin-block-start: 0;
		margin-block-end: 0;
		margin-inline-start: 0;
		margin-inline-end: 0;
		padding-inline-start: 0;
		margin: 0 -15px;

		&__card {
			padding: 2 * $section-margin;
			box-shadow: -1px 5px 15px -5px rgb(21 38 47 / 24%);
			border: 1px solid #efefef;
			background: #fff;
			height: 100%;

			& > h2 {
				text-align: center;
				padding-bottom: $section-margin;
				border-bottom: 1px solid #efefef;
				margin-bottom: $section-margin;
			}

			& > .about-us__pledge-cards-container__image-list {
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				flex-wrap: wrap;
				gap: $section-margin;

				& > img {
					object-fit: contain;
					max-height: 60px;
					max-width: 160px;
				}
			}
		}

		&__community-outreach {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			gap: $section-margin;

			list-style-type: none;
			margin-block-start: 0;
			margin-block-end: 0;
			margin-inline-start: 0;
			margin-inline-end: 0;
			padding-inline-start: 0;

			& > li {
				margin: 0;
				padding: $section-margin;
				border: 1px solid #efefef;
				background: rgba(128, 128, 128, 0.05);

				& figure {
					margin: 0;

					& img {
						max-width: 160px;
						max-height: 64px;
					}
				}
			}
		}
	}
}

// CAREERS
.careers {
	&__intro-block {
		display: flex;
		flex-direction: row;
		gap: 2 * $section-margin;
		margin-bottom: 10px;
		align-items: center;

		img {
			width: 30vw;
			object-fit: contain;
			height: fit-content;
		}
	}

	&__benefits-block {
		list-style-type: none;
		margin-block-start: 0;
		margin-block-end: 0;
		margin-inline-start: 0;
		margin-inline-end: 0;
		padding-inline-start: 0;
		margin: -15px;

		&__card {
			display: flex;
			flex-direction: row;
			align-items: center;
			border: 1px solid $brand-blue;
			padding: $section-margin;
			overflow: hidden;
			height: 100%;

			@media screen and (max-width: $width-mobile--lg) {
				flex-direction: column;
			}

			&__title {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				color: $brand-blue;
				border: 0 solid #efefef;
				border-right-width: 1px;

				padding: $section-margin;
				margin: 0;

				@media screen and (max-width: $width-mobile--lg) {
					border-right-width: 0;
					border-bottom-width: 1px;
				}

				img {
					max-width: 220px;
					height: fit-content;
					object-fit: contain;
				}
			}

			&__content {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				gap: 2 * $section-margin;
				padding: $section-margin;

				@media screen and (max-width: $width-mobile--lg) {
					flex-direction: column;
				}

				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;

					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;

					@media screen and (max-width: $width-mobile--lg) {
						align-items: center;
					}
				}
			}
		}

		.compensation {
			display: flex;

			@media screen and (min-width: $width-tablet--lg) {
				align-items: flex-end;
			}
		}

		.health {
			display: flex;

			@media screen and (min-width: $width-tablet--lg) {
				align-items: flex-start;
			}
		}

		.discounts {
			display: flex;

			@media screen and (min-width: $width-tablet--lg) {
				align-items: center;
			}
		}
	}

	@media screen and (max-width: $width-tablet--lg) {
		&__intro-block,
		&__benefits-list {
			flex-direction: column;
		}

		&__intro-block {
			img {
				width: 100%;
				object-fit: contain;
				height: fit-content;
				margin: 10px 0 0;
			}
		}
	}
}

// MANAGERS PAGE
.managers {
	&__content-title {
		font-size: 24px;
	}

	&__list {
		list-style: none;
		padding: 15px 0 0;
		margin: 0;

		li {
			border: 1px solid #ccc;
			padding: 10px;
			display: flex;
			column-gap: 20px;

			div.manager-image {
				width: 136px;
				height: 110px;

				border: 1px solid $brand-blue;
				box-sizing: border-box;

				img {
					object-fit: cover;
					width: 136px;
					height: 110px;
				}
			}

		}

		li ~ li {
			border-top: none;
		}
	}

	&__text-content {
		display: flex;
		flex-direction: column;
		column-gap: 5px;
		margin: 10px 0;

		p {
			margin-right: 10px;
			margin-top: 0 !important;
		}
	}

	@media screen and (max-width: $width-tablet) {
		&__content-title {
			margin-top: 15px;
		}

		&__list {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				flex-direction: column;

				img {
					width: 100%;
					object-fit: cover;
					height: fit-content;
				}
			}
		}

		&__text-content {
			flex-direction: column;
		}
	}
}
